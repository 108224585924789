
<section class="loginPage">
		<!-- <div class="loginOverlay"></div> -->
		<div class="container-fluid">
				<div class="row">

					<div class="col-12 col-sm-12 col-md-12">
						<div class="loginLeftSec">
							<div class="card card-signin">
								<div class="card-body">
									<img src="assets/images/ez-franchise-logo.png" class="Skypark logo">
									<!-- <h2 class="text-center"></h2> -->
									<h4>Sign Into Your Account</h4>
									<form class="form-signin">
										<div class="form-label-group">
											<i class="fa fa-envelope" aria-hidden="true"></i>
											<input type="email" id="inputEmail" class="form-control" placeholder="" [(ngModel)]="admin.email" [ngModelOptions]="{standalone: true}" autofocus>
										</div>

										<div class="form-label-group">
											<i class="fa fa-lock" aria-hidden="true"></i>
											<input type="password" id="inputPassword" class="form-control" placeholder="" [(ngModel)]="admin.password" [ngModelOptions]="{standalone: true}">
										</div>

									</form>

									<div class="alert alert-danger" [ngClass]="{'hide': !showErrorAlert}">
										<strong>Login Failed!</strong> Invalid credentials.
									</div>
									<div class="loginBtn">
										<button class="btn btn-lg btn-primary btn-block text-uppercase" id="loginBtn" (click)="login()" [disabled]="loading">LOGIN	<div class="lds-ring" *ngIf="loading"><div></div><div></div><div></div><div></div></div></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
		</div>
</section>
