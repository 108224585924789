import { Component, OnInit } from '@angular/core';
import { AppSettings, HelperService } from '../../services/_services';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public buildingImageFooter : any = true;
  constructor(
    private helperService: HelperService,
    private router: Router,
  ) {
    this.router.events.subscribe((val) => {
    });
  }

  ngOnInit() {
  }
}
