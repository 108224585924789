import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService, SEOService, AppSettings, HelperService } from '../../services/_services';
import { Location } from '@angular/common';

import { Router, Route, ActivatedRoute, NavigationEnd } from "@angular/router";
// import { SiginUpComponent } from '../../entry-components/sigin-up/sigin-up.component';

// Sweet Alerts
import Swal from 'sweetalert2'
declare var $;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public PROJECT_ID         : any = AppSettings.PROJECT_ID;
  public isUserLoggedIn     : any = false;
  public loading            : any = false;
  public toggleSide         : any = false;
  public sideNav            : any = false;
  public user :any = {
    'first_name'            : '',
    'last_name'             : '',
    'email'                 : '',
    'password'              : '',
    'phone_number'          : '',
    'dob'                   : null,
  }
  public searchByID         : any = "";
  public searchingLoad      : any = false;
  public atHomePage         : any = false;
  public propuseSub         : any;
  public Purpose            : any;
  public newLeads_Count        : any = 0;
  public newAppointments_Count : any = 0;
  public leadsInterval         : any;

  public activeUser            : any;
  public activeRoute           : any;
  public routerSubscription    : any;
  public menus :any = [
    { name: "Listing",               route: "/listings",               active: false, onlyAdmin: false, specialAccess: false },
    { name: "Leads",                 route: "/leads",                  active: false, onlyAdmin: false, specialAccess: false },
    { name: "Applications",          route: "/franchise-applications", active: false, onlyAdmin: false, specialAccess: false },
    { name: "Add Franchise",         route: "/add-franchise",          active: false, onlyAdmin: false, specialAccess: false },
  ]

  public menuList :any = {
    Admin: [
      { name: "Listing",               route: "/listings",               active: false, onlyAdmin: false, specialAccess: false, checkInclude: false,  showInMenu: true  },
      { name: "Leads",                 route: "/leads",                  active: false, onlyAdmin: false, specialAccess: false, checkInclude: false,  showInMenu: true  },
      { name: "Applications",          route: "/franchise-applications", active: false, onlyAdmin: false, specialAccess: false, checkInclude: false,  showInMenu: true  },
      { name: "Add Franchise",         route: "/add-franchise",          active: false, onlyAdmin: false, specialAccess: false, checkInclude: false,  showInMenu: true  },
      { name: "Leads Act",             route: "/activity",               active: false, onlyAdmin: false, specialAccess: false, checkInclude: true,   showInMenu: false  },
      { name: "Application Details",   route: "/application-details",    active: false, onlyAdmin: false, specialAccess: false, checkInclude: true,   showInMenu: false },
      { name: "Edit Application",      route: "/edit-application",       active: false, onlyAdmin: false, specialAccess: false, checkInclude: true,   showInMenu: false },
      { name: "Edit Frnachise",        route: "/edit-franchise",         active: false, onlyAdmin: false, specialAccess: false, checkInclude: true,   showInMenu: false },
    ],
    Agent: [
      { name: "Listing",               route: "/listings",               active: false, onlyAdmin: false, specialAccess: false, checkInclude: false,  showInMenu: true  },
      { name: "Leads",                 route: "/leads",                  active: false, onlyAdmin: false, specialAccess: false, checkInclude: false,  showInMenu: true  },
      { name: "Applications",          route: "/franchise-applications", active: false, onlyAdmin: false, specialAccess: false, checkInclude: false,  showInMenu: true  },
      { name: "Add Franchise",         route: "/add-franchise",          active: false, onlyAdmin: false, specialAccess: false, checkInclude: false,  showInMenu: true  },
      { name: "Leads  Act",            route: "/activity",               active: false, onlyAdmin: false, specialAccess: false, checkInclude: false,  showInMenu: false  },
      { name: "Application Details",   route: "/application-details",    active: false, onlyAdmin: false, specialAccess: false, checkInclude: true,   showInMenu: false },
      { name: "Edit Application",      route: "/edit-application",       active: false, onlyAdmin: false, specialAccess: false, checkInclude: true,   showInMenu: false },
    ],
  };

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private seoService: SEOService,
    private helperService: HelperService,
    private _location: Location,
  ) {
      // Get Current User
      this.activeUser = this.authenticationService.get_currentUserValue();

      if(this.activeUser.is_admin == '1') {
        this.menus = this.menuList.Admin;
      } else if(this.activeUser.is_agent == '1') {
        this.menus = this.menuList.Agent;
      }

      this.routerSubscription = this.router.events.subscribe((val) => {
        if(val instanceof NavigationEnd) {
          this.activeRoute = val.url;

          // console.log("this.activeRoute: ",this.activeRoute.indexOf('registration-form'));


          let menuFound = false;
          for (let i = 0; i < this.menus.length; i++) {
              if(!this.menus[i].checkInclude && this.menus[i].route == this.activeRoute) {
                  this.menus[i].active = true;
                  menuFound            = true;
              }
              else if(this.menus[i].checkInclude && this.activeRoute.indexOf(this.menus[i].route) != '-1') {
                  this.menus[i].active = true;
                  menuFound            = true;
              }
              else
                  this.menus[i].active = false;
          }

          // if(this.activeRoute.indexOf('registration-form') != '-1') {
          //   menuFound = true;
          // }

          // If menu not found then redirect the user to first page
          if(!menuFound) {
            this.router.navigate([this.menus[0].route]);
            this.menus[0].active = true;
          }
   			}
	    });


      // this.routerSubscription = this.router.events.subscribe((val) => {
      //   if(val instanceof NavigationEnd) {
      //     this.activeRoute = val.url;
      //
      //     if(val.url == '/') {
      //         this.menus[0].active = true;
      //     } else {
      //         for (let i = 0; i < this.menus.length; i++) {
      //             if(this.menus[i].route == val.url)
      //               this.menus[i].active = true;
      //             else
      //               this.menus[i].active = false;
      //         }
      //     }
      //
   		// 	}
	    // });

      // this.routerSubscription = this.router.events.subscribe((val) => {
      //   if(val instanceof NavigationEnd) {
      //     this.activeRoute = val.url;
      //
      //     // console.log("this.activeRoute: ", this.activeRoute)
      //
      //     let menuFound = false;
      //     for (let i = 0; i < this.menus.length; i++) {
      //         if(this.menus[i].route == val.url) {
      //             this.menus[i].active = true;
      //             menuFound            = true;
      //         } else if(val.url.indexOf('activity') != -'1')
      //             menuFound            = true;
      //         else
      //             this.menus[i].active = false;
      //     }
      //
      //     // If menu not found the redirect the user to first page
      //     if(!menuFound)
      //       this.router.navigate([this.menus[0].route]);
      //
      //     // if(val.url == '/') {
      //     //     this.menus[0].active = true;
      //     // } else {
      //     //
      //     // }
   		// 	}
	    // });
  }

  ngOnInit() {
    // Subscribe To User Local Login
    this.authenticationService.currentUserS$.subscribe(user => {
      if (user) {
          this.user = user;
          // this.user = user;
          this.isUserLoggedIn = true;
          // console.log("this.user: ",this.user);
      } else {
          // // Move user to homepage
          // let self = this;
          // setTimeout(function() {
          //   self.isUserLoggedIn = false;
          //   console.log("User LoggedOut");
          //   if (self.activatedRoute.snapshot.routeConfig.path.indexOf('dashboard') !== -1) {
          //       self.router.navigate(['/']);
          //   }
          // }, 500)
      }
    });
  }



  playBell() {
    let audio = new Audio();
    audio.src = "../../../assets/audio/simple_notification.mp3";
    audio.load();
    audio.play();
  }



  ngOnDestroy() {
    if (typeof this.routerSubscription != 'undefined') {
      //prevent memory leak when component destroyed
      this.routerSubscription.unsubscribe();
    }
  }

  showMeanu(menu) {
    let check = true;
    if(menu.onlyAdmin) {
      if(this.activeUser.is_admin === 1)
        check = true;
      else
        check = false;
    }

    // Check for special access for non-admin users
    if(menu.specialAccess && this.activeUser.is_admin != 1) {
        if(this.activeUser.hasSpecialAccess === 1)
          check = true;
        else
          check = false;
    }
    return check;
  }

  logout() {
    // this.authenticationService.logout().then(resp => {
    //   if(resp)
    //     this.router.navigate(['login']);;
    // });

    this.authenticationService.simpleLogout();
    this.router.navigate(['/login']);
  }
  toggleSidebar() {
    this.toggleSide = !this.toggleSide;
    this.sideNav = !this.sideNav;
    $('body').toggleClass("overflow-hidden");
  }
  closeSideNav(){
    this.toggleSide = false;
    this.sideNav = false;
    $('body').removeClass("overflow-hidden");
  }
}
