export class AppSettings {
  public static API_ENDPOINT  =  'https://api.ez-franchise.com/api/';
   // public static API_ENDPOINT  = 'http://192.168.18.30:8000/api/';

   public static TEMP_IMAGES   = 'https://api.ez-franchise.com/uploads/temp/';
   // public static TEMP_IMAGES   = 'http://192.168.18.30:8000/uploads/temp/';

   public static IMG_ENDPOINT  = 'https://api.ez-franchise.com/public/images/';
   // public static IMG_ENDPOINT  = 'http://192.168.18.30:8000/images/';

   public static FILE_DOWNLOAD = 'https://api.ez-franchise.com/public/docs/';
   // public static FILE_DOWNLOAD = 'http://192.168.18.30:8000/docs/';
   public static PROJECT_ID    = 1;

   public static leadSources        = ['UAN', 'Facebook', 'Website', 'Other'];
   public static leadStatus         = ['New Lead', 'In Contact', 'Call back', 'Not interested', 'Wrong No', 'Appointment', 'Reschedule', 'No response', 'Duplicate', 'Sale', 'PFSOE', 'Visited'];
   public static AppointmentStatus  = ['Appointment', 'Reschedule', 'No response', 'Call back', 'Visited', 'Sale', 'Not interested', 'PFSOE'];
   public static visitStatus        = ['Visited', 'In Contact', 'Sale', 'PFSOE'];
   public static unAssignableStatus = ['Not interested', 'Wrong No', 'No response', 'Duplicate', 'PFSOE'];
   public static BinStatus          = ['New Lead', 'Call back', 'Not interested', 'Wrong No', 'Appointment', 'Reschedule', 'Visited', 'Follow Up', 'No response', 'Duplicate', 'Sale', 'PFSOE'];

   public static birthYear  = [ 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004,
      2003, 2002, 2001, 2000, 1999, 1998, 1997, 1996, 1995, 1994, 1993, 1992, 1991, 1990, 1989, 1988, 1987, 1986, 1985, 1984, 1983, 1982,
      1981, 1980, 1979, 1978, 1977, 1976, 1976, 1975, 1974, 1973, 1972, 1971, 1970, 1969, 1968, 1967, 1966, 1965, 1964, 1963, 1962, 1961,
      1960, 1959, 1958, 1957, 1956, 1955, 1954, 1953, 1952, 1951, 1950, 1949, 1948, 1947, 1946, 1945, 1944, 1943, 1942, 1941, 1940, 1939,
      1938,  1937, 1936, 1935, 1934, 1933, 1932, 1931, 1930, 1929, 1928, 1927, 1926, 1925, 1924, 1923, 1922, 1921, 1920
  ];
  public static months  = [
    { name:"January",  value: "01"},
    { name:"February", value: "02"},
    { name:"March",    value: "03"},
    { name:"April", value: "04"},
    { name:"May", value: "05"},
    { name:"June", value: "06"},
    { name:"July", value: "07"},
    { name:"August", value: "08"},
    { name:"September", value: "09"},
    { name:"October", value: "10"},
    { name:"November", value: "11"},
    { name:"December" , value: "12"},
  ];
  public static days  = ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31"];
}
