<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== --
<div id="main-wrapper">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <!-- <app-navigation></app-navigation>
    <!-- ============================================================== --
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== ->
    <app-sidebar></app-sidebar>
    <!-- ============================================================== --
    <!-- End Left Sidebar - style you can find in sidebar.scss  --
    <!-- ============================================================== --
    <!-- ============================================================== --
    <!-- Page wrapper  --
    <!-- ============================================================== --
    <div class="page-wrapper">
        <!-- ============================================================== --
        <!-- Container fluid  --
        <!-- ============================================================== --
        <div class="container-fluid">
            <app-breadcrumb></app-breadcrumb>
            <!-- ============================================================== --
            <!-- Start Page Content --
            <!-- ============================================================== --
            <router-outlet></router-outlet>
            <!-- ============================================================== --
            <!-- End Start Page Content -->
            <!-- ============================================================== --
        </div>
        <!-- ============================================================== --
        <!-- End Container fluid  --
        <!-- ============================================================== --
        <!-- ============================================================== --
        <!-- footer --
        <!-- ============================================================== --
        <footer class="footer">
            © 2019 Gharbaar
        </footer>
        <!-- ============================================================== --
        <!-- End footer --
        <!-- ============================================================== --
    </div>
    <!-- ============================================================== --
    <!-- End Page wrapper  --
    <!-- ============================================================== --
</div> -->

<!-- Header -->
<app-header></app-header>
<div class="main-content">
  
  <!-- Pages -->
  <router-outlet></router-outlet>
    <app-footer></app-footer>
</div>
