<!-- Main Header -->
<section class="af-navbar">
    <div class="mynavbar" [ngClass]="{'mynavbar-dashboard': !atHomePage}">
      <div class="af-full-overlay" [ngClass]="{'af-show': sideNav}" (click)="toggleSidebar()"></div>
        <div class="custom-container container">
            <nav class="navbar">
                <div class="">
                    <div class="navbar-header">
                        <!-- <button type="button" class="navbar-toggle collapsed navbar-expand-md" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button> -->
                        <span class="mymenubtn" (click)="toggleSidebar()">&#9776;</span>
                        <a href="javascript:void(0)" routerLink="/" class="mylogo" style="outline:none; text-decoration: none;">
                            <img src="assets/images/ez-franchise-logo.png" alt="logo">

                        </a>
                    </div>
                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="collapse navbar-collapse" [ngClass]="{'open' : toggleSide}" id="bs-example-navbar-collapse-1">
                        <i class="fa fa-times af-close" aria-hidden="true" (click)="toggleSidebar()"></i>
                        <ul class="nav navbar-nav mx-auto">
                          <ng-container *ngFor="let menu of menus">
                            <li *ngIf="menu.showInMenu">
                              <a href="javascript:;" routerLink="{{ menu.route }}" [ngClass]="{'active': menu.active }">
                                {{ menu.name }}

                                <span class="badge badge-info" *ngIf="menu.route == '/leads' && newLeads_Count > 0">{{ newLeads_Count }}</span>
                                <span class="badge badge-info" *ngIf="menu.route == '/appointment-leads' && newAppointments_Count > 0">{{ newAppointments_Count }}</span>
                              </a>
                            </li>
                          </ng-container>
                          <li *ngIf="isUserLoggedIn">
                              <div class="dropdown">
                                <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                  {{ 'Welcome ' + user.name}}
                                  <span class="caret"></span>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">

                                  <!-- <li><a href="javascript:void(0)" routerLink="/account" routerLinkActive="active-route">Account Settings</a></li> -->
                                  <!-- <li><a href="javascript:void(0)">Account</a></li> -->
                                  <li>
                                      <button type="button" (click)="logout()" [disabled]="loading">Logout</button>
                                  </li>
                                </ul>
                              </div>
                          </li>
                        </ul>
                    </div><!-- /.navbar-collapse -->
                </div>
            </nav>
        </div>
    </div>
</section>
