import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PageComponent } from './pages/pages.component';
import { LoginComponent } from './pages/component/login/login.component';
import { AuthGuard } from './services/_helpers';

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: './pages/pages.module#PagesModule'
  // },
  {
    path: '',
    component: PageComponent,
    canActivate: [ AuthGuard ],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        // loadChildren: './pages/pages.module#PagesModule'
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login',
    }
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), NgbModule],
  exports: [RouterModule]
})
export class AppRoutingModule {}
